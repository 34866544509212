'use client';

import React from "react";
import PageWrapper from "@/components/layouts/PageWrapper/PageWrapper";
import ChatList from "@/components/chats/List";
import ChatRoom from "@/components/chats/Room";

const HomeClient = () => {
  return (
    // <PageWrapper>
      <></>
    // </PageWrapper>
  )
}

export default HomeClient;